<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="6" sm="12">
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.email') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? recommendationList.email : recommendationList.email }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                      <p class="text-dark">{{ $t('warehouse_service.mobile_no') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark"> {{ $i18n.locale === 'bn' ? '০' : '0' }}{{  $n(recommendationList.mobile_no ,{ useGrouping: false }) }}  </p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.name') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? recommendationList.name_bn : recommendationList.name }}</p>
                      </b-col>
                    </b-row>
                     <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.service_type') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? recommendationList.service_type_bn : recommendationList.service_type }}</p>
                      </b-col>
                    </b-row>
                      <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.address') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? recommendationList.address_bn : recommendationList.address }}</p>
                      </b-col>
                    </b-row>
                     <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.father_name') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? recommendationList.father_name_bn : recommendationList.father_name }}</p>
                      </b-col>
                    </b-row>
                     <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.land_type_id') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ recommendationList.land_type_id === 1 ? this.$i18n.locale === 'bn' ? 'নিজস্ব' : 'Own' : this.$i18n.locale === 'bn' ? 'ইজারা' : 'Lease' }}</p>
                      </b-col>
                    </b-row>
                     <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.land_area') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{  $n(recommendationList.land_area) }}</p>
                      </b-col>
                    </b-row>
                     <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_information.remarks') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? recommendationList.remarks_bn : recommendationList.remarks }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import { RegRecomDetailsList } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    if (this.$props.id) {
      const tmp = this.getRegDetailsList()
      Object.freeze(tmp)
      this.recommendationList = tmp
    }
  },
  data () {
    return {
      recommendationList: ''
    }
  },
  computed: {
  },
  methods: {
    async getRegDetailsList () {
      const result = await RestApi.getData(authServiceBaseUrl, `${RegRecomDetailsList}/${this.$props.id}`)
      if (result.success) {
        this.recommendationList = result.data
       }
      this.loader = false
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
